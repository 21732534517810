<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">物业管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/informationInfo">报修管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="报修人" prop="ownerName">
        <el-input type="text" size="mini" v-model="queryModel.ownerName"></el-input>
      </el-form-item>
      <el-form-item label="提交时间" prop="createTimeRanges">
        <el-date-picker
          size="mini"
          v-model="queryModel.createTimeRanges"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="报修状态" prop="status">
        <el-select v-model="queryModel.status" size="mini">
          <el-option v-for="item in statusList" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group"></el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column prop="name" sort-by="name_" label="业主名称" width="100"></el-table-column>
      <el-table-column prop="phone" sort-by="phone" label="手机号码" width="130"></el-table-column>
      <el-table-column prop="community" sort-by="community_" label="报修人房号" width="120"></el-table-column>
      <el-table-column prop="content" sort-by="content_" label="报修内容" width="220"></el-table-column>
      <el-table-column
        prop="img"
        label="报修图片"
        width="200"
      >
        <template slot-scope="{ row }">
          <template v-if="row.img!=null">
              <a 
              v-for="(imageUrl,index) in row.img.split(',')"
              :href="imageUrl" :key="index" target="_blank">
                <el-avatar
                  :size="48"
                  shape="square"
                  :src="
                    imageUrl +'?x-oss-process=image/resize,m_fill,w_64,h_64'
                  "
                ></el-avatar>
              </a>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" sort-by="create_time" label="提交时间" width="150"></el-table-column>
      <el-table-column prop="status" sort-by="status_" label="报修状态" width="180">
        <template slot-scope="scope">
          <p v-if="scope.row.status=='0'">待解决</p>
          <p v-if="scope.row.status=='1'">已解决</p>
          <p v-if="scope.row.status=='2'">已结束</p>
        </template>
      </el-table-column>
      <el-table-column prop="returnContent" sort-by="return_content" label="解决方案" width="220"></el-table-column>
      <el-table-column prop="returnTime" sort-by="return_time" label="解决时间" width="150"></el-table-column>

      <el-table-column label="操作" width="220" fixed="right">
        <template slot-scope="{row}">
          <el-button v-if="row.status=='2'" size="mini" type="success" disabled>已解决</el-button>
          <el-button v-else-if="row.status=='1'" size="mini" type="success" @click="handleEdit(row)">已回复</el-button>
          <el-button v-else size="mini" type="success" @click="handleEdit(row)">提交解决方案</el-button>
          <el-button v-if="row.status=='2'" size="mini" type="danger" disabled>结束</el-button>
          <el-button v-else size="mini" type="danger" @click="handleFinished(row)">结束</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <informationInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></informationInfo-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import InformationInfoDetail from "./informationInfo-detail";
import informationInfoApi from "@/api/base/informationInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "BaseInformationInfoWarrantyList",
  data() {
    var self = this;

    return {
      queryModel: {
        id: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: "",
        delFlag: "",
        ownerName: "",
        community: "",
        content: "",
        status: "",
        returnContent: "",
        returnTime: "",
        type: "2",
        createTimeRanges:[],
      },
      loading: false,
      tableData: [],
      statusList:[
        {
        value:"0",
        name:"待解决"
        },
        {        
        value:"1",
        name:"已解决"
        },
        {        
        value:"2",
        name:"已结束"
        }
      ],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: ""
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      
      formData.append("type", "2");
      formData.append("name", self.queryModel.ownerName);
      if(self.queryModel.createTimeRanges!=null&&self.queryModel.createTimeRanges!=""){
        formData.append("createTimeRanges", self.queryModel.createTimeRanges.toString());
      }
      formData.append("status", self.queryModel.status);

      informationInfoApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleFinished(record){
      var self = this;
      self
        .$confirm("是否结束报修?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          informationInfoApi.finished(record.id).then(function(response){
            var jsonData = response.data;

            if (jsonData.result) {
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "投诉已结束!"
              });
            }
          })
        })

    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          informationInfoApi.remove(record.id).then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!"
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        informationInfoApi.batchRemove(idList).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    }
  },
  mounted: function() {
    this.changePage(1);
  },
  components: {
    "informationInfo-detail": InformationInfoDetail
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>